import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {auditTime, map, tap} from 'rxjs/operators';

import {Group} from '../../models';
import {ProfileService} from '../auth';
import {MongoService} from './../mongo/mongo.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  list: Observable<Group[]>;

  filteredUsers = [];

  constructor(private profile: ProfileService, private mongoService: MongoService) {}

  //group by orgaization
  getByOrg(orgId: string): Observable<Group[]> {
    return this.mongoService.getAllGroups(orgId).pipe(map((res: any) => res.data));
  }
  //group not in orgaization
  getNotInOrg(orgId: string): Observable<Group[]> {
    return this.list.pipe(
      auditTime(1000),
      tap(() => orgId),
      map((groups) => groups.filter((group) => group.orgId !== orgId)),
      tap((groups) => groups),
    );
  }
  //create group
  async add(item: any) {
    item.createdBy = this.profile.uid;
    const response: any = await this.mongoService.createGroup(item).toPromise();
    return response.data.id;
  }

  //update group
  async updateGroup(item: any, groupId: string) {
    item.updatedBy = this.profile.uid;
    const response: any = await this.mongoService.updateGroup({...item, id: groupId}).toPromise();
    return response.data.id;
  }

  //get extra addd org members by orgId
  getGroupsInfo(orgId?: string, details = true) {
    return this.mongoService.getAllGroups(orgId, details).pipe(map((res: any) => res.data));
  }
}
